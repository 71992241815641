import React from 'react'
import { navigate } from '@reach/router'
import { format, parseISO } from 'date-fns'
import {
  TileContainer,
  Image,
  TextCaption,
  TextMain,
  StatsRow,
  Stat,
  LastUpdated,
  Status,
  StatusIndicator,
} from './styles'
import NoImage from 'public/images/no_image_new.png'
import { displayPriceAndRange } from 'lib/utils/helper'
import { encodeId } from 'src/lib/utils/helper'
function ProductTile({ product, currency }) {
  const {
    title,
    sku,
    itemId,
    images,
    priceRange,
    variantCount,
    priceLastUpdatedAt,
    priceStartDate,
    priceEndDate,
  } = product
  const imageurl = (images.length && images[0].source[0].url) || NoImage
  const priceListId =
    new URL(window.location.href).searchParams.get('priceListId') || ''
  const status = inferStatus(priceStartDate, priceEndDate)
  return (
    <TileContainer
      tabIndex='0'
      onClick={() =>
        navigate(`/offers/price/product/${encodeId(sku)}/${priceListId}`)
      }
      onKeyDown={(e) =>
        e.keyCode === 32 &&
        navigate(`/offers/price/product/${encodeId(sku)}/${priceListId}`)
      }
      data-testid={`product-${itemId}`}
    >
      <Image src={imageurl} onError={(e) => (e.target.src = NoImage)} />
      <Stat>
        <TextCaption>{sku}</TextCaption>
        <TextMain title={title}>{title}</TextMain>
      </Stat>
      <StatsRow>
        <Stat>
          <TextCaption>Price range</TextCaption>
          <TextMain>{displayPriceAndRange({ priceRange, currency })}</TextMain>
        </Stat>
        <Stat>
          <TextCaption>Variants</TextCaption>
          <TextMain>{variantCount}</TextMain>
        </Stat>
      </StatsRow>
      {priceLastUpdatedAt && (
        <LastUpdated>
          {format(
            parseISO(priceLastUpdatedAt),
            "'Last updated' MMM d hh:mm aa"
          )}
        </LastUpdated>
      )}
      {status && (
        <Status>
          <StatusIndicator />
          {status}
        </Status>
      )}
    </TileContainer>
  )
}

const inferStatus = (startDate, endDate) => {
  if (!startDate || !endDate) return null
  const now = new Date()
  const start = parseISO(startDate)
  const end = parseISO(endDate)

  if (now > end) return 'Expired'
  if (now < start) return 'Scheduled'
  if (now >= start && now <= end) return 'Active'
  return null
}

export default ProductTile
