import React, { memo } from 'react'
import { Icon } from '@teamfabric/copilot-ui'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'

import { StyledPageHeading, StyledClick } from './styles'

function PageHeading ({
  separator,
  backRoute,
  handleClick,
  children,
  ...others
}) {
  return backRoute ? (
    <Link to={backRoute}>
      <StyledPageHeading separator={separator} {...others}>
        <Icon iconName='LeftArrow' size={16} />
        <div className='children'>{children}</div>
      </StyledPageHeading>
    </Link>
  ) : (
    <StyledClick onClick={() => handleClick()} className='click'>
      <StyledPageHeading separator={separator} {...others}>
        <Icon iconName='LeftArrow' size={16} />
        <div className='children'>{children}</div>
      </StyledPageHeading>
    </StyledClick>
  )
}

PageHeading.propTypes = {
  separator: PropTypes.bool,
  backRoute: PropTypes.string,
  children: PropTypes.element
}

PageHeading.defaultProps = {
  separator: true,
  children: null,
  backRoute: ''
}

export default memo(PageHeading)
