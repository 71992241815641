import React from 'react'
import { navigate } from '@reach/router'
import { Table } from '@teamfabric/copilot-ui'
import { HEADERS } from './headers'
import { StyledListView } from './styles'
import isEmpty from 'lodash/isEmpty'
import { renderNoResultWithMessage } from 'components/table-columns'
import { encodeId } from 'src/lib/utils/helper'
const ListView = ({ data, currency }) => {
  const priceListId =
    new URL(window.location.href).searchParams.get('priceListId') || ''
  const onRowClick = (event, rowDetails) => {
    if (
      event.target.className &&
      !event.target.className.includes('expansion-cell')
    ) {
      const isChild =
        rowDetails.variants && rowDetails.variants.length > 0 ? true : false
      if (!isChild) {
        navigate(
          `/offers/price/product/${encodeId(rowDetails.sku)}/${priceListId}`
        )
      } else {
        navigate(
          `/offers/price/variant/${encodeId(
            rowDetails.itemId.toString()
          )}/${priceListId}`
        )
      }
    }
  }

  const renderTable = () => {
    if (isEmpty(data)) {
      return renderNoResultWithMessage(HEADERS.length + 1)
    } else {
      return null
    }
  }
  return (
    <StyledListView>
      <div data-testid='table' className='table'>
        <Table
          cellClassName='table-cell'
          columns={HEADERS(currency)}
          data={data}
          enableSort
          onRowClick={(event, rowDetails) => onRowClick(event, rowDetails)}
          render={renderTable}
          onAllRowSelect={() => {}}
          collapseOnRowClick={false}
        />
      </div>
    </StyledListView>
  )
}

export default ListView
