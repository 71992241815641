import React, { Suspense } from 'react'
import { Router, Redirect } from '@reach/router'
import { Empty } from '@teamfabric/copilot-ui'
import ProductList from 'modules/price/product-list'
import Product from 'modules/price/product'
import Variant from 'modules/price/variant'
import PromoView from 'modules/promo/view'
import SideNavigation from 'components/navigation'
import { hasPermission } from 'src/api/rolePermissions'
import { READ_PERMISSIONS, PERMISSION_ERROR } from 'src/lib/constants/constants'
import { isFeatureActive } from 'src/api/featureFlag'
import { FEATURE_FLAGS } from 'src/general-config'

const Settings = React.lazy(() => import('modules/settings'))
const BulkImportHistory = React.lazy(() => import('modules/price/bulk-import'))
const PromoListing = React.lazy(() => import('modules/promo/list'))
const PromoCreate = React.lazy(() => import('modules/promo/create'))
const PromoEdit = React.lazy(() => import('modules/promo/edit'))
const PromoClone = React.lazy(() => import('modules/promo/clone'))
const GlobalExclusionsList = React.lazy(() =>
  import('modules/global-exclusions/list')
)
const GlobalExclusionsCreate = React.lazy(() =>
  import('modules/global-exclusions/create')
)
const GlobalExclusionsEdit = React.lazy(() =>
  import('modules/global-exclusions/edit')
)
const CustomerSegments = React.lazy(() =>
  import('src/modules/settings/customer-segments')
)
const Redemption = React.lazy(() =>
  import('src/modules/promo/redemption/redemption')
)
const RedemptionExportHistory = React.lazy(() =>
  import(
    'src/modules/promo/redemption/redemption-export-history/redemption-export-history'
  )
)

const DateTimePicker = React.lazy(() => import('src/components/date-picker'))
const AppContent = ({ children }) => {
  return (
    <div className='app_root'>
      <main className='d-flex w-100 app_wrapper'>
        <SideNavigation />
        <div className='app_children'>{children}</div>
      </main>
    </div>
  )
}

const AppRoutes = ({ user }) => {
  const active = isFeatureActive({ flagName: FEATURE_FLAGS.DS4 })
  if (!hasPermission(READ_PERMISSIONS.READ_OFFERS)) {
    return (
      <Empty
        className='empty'
        primaryText={PERMISSION_ERROR.OFFER_ERROR}
        secondaryText={PERMISSION_ERROR.SECONDARY_TEXT}
      />
    )
  }
  return (
    <main id='app_root' data-testid='ds3-app'>
      <Suspense fallback={() => <div>Loading</div>}>
        {!active && <DateTimePicker />}
        <Router>
          <AppContent path='/' user={user}>
            <BulkImportHistory path='/offers/price/bulk' />
            <ProductList path='/offers/price/product-list/:keyword' />
            <ProductList path='/offers/price/product-list' />
            <Variant path='/offers/price/variant/:variantId/:priceListId' />
            <Product path='/offers/price/product/:productId/:priceListId' />
            <PromoListing path='/offers/promo-list' />
            <BulkImportHistory
              path='/offers/coupon-import-history'
              type='coupon'
            />
            <PromoEdit path='/offers/promo-edit/:promoId' />
            <PromoView path='/offers/promo/:promoId' />
            <PromoCreate path='/offers/promo-create' />
            <PromoListing path='/offers/coupon-list' />
            <PromoEdit path='/offers/coupon-edit/:couponId' />
            <PromoView path='/offers/coupon/:couponId' />
            <PromoClone path='/offers/coupon-clone/:couponId' />
            <PromoCreate path='/offers/coupon-create' />
            <Redemption path='/offers/coupon/redemption' />
            <RedemptionExportHistory path='/offers/coupon/redemption/exports-history' />
            <Redirect from='/offers/' to='/offers/price/product-list' noThrow />
            <GlobalExclusionsList path='/offers/settings/exclusions-list' />
            <GlobalExclusionsCreate path='/offers/settings/exclusions-create' />
            <GlobalExclusionsEdit path='/offers/settings/exclusions-edit/:skuListId' />
            <GlobalExclusionsEdit
              path='/offers/settings/exclusions/:skuListId'
              viewMode
            />
            <CustomerSegments path='/offers/settings/customer-segments' />
            <Settings path='/offers/settings/price-list' />
          </AppContent>
        </Router>
      </Suspense>
    </main>
  )
}

export default AppRoutes
