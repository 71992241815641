import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
    .app_wrapper{
        position: static;
        width: 100%;
        display: flex;
        flex-direction: row;
        font-family: ${props => props.theme.typography.fontFamily};
        height: calc(100vh - 60px);
        background-color: rgb(115, 127, 143, 0.1);
        box-shadow: rgba(63, 63, 68, 0.15) 0px 1px 3px 0px, rgba(63, 63, 68, 0.05) 0px 0px 0px 1px;
    }
    .empty{
      height: 100vh;
    }
    .app_children {
      width: calc(100% - 263px);
      height: 100%;
      overflow: auto;
      padding: 40px 25px 0px;
      box-sizing: border-box;
      display: flex;
      flex-grow: 1;
      padding-right: 113px; // TODO remove this once the sidebar comes

      & > div:first-of-type {
        flex-grow: 1;
      }
    }
    .app_children > * {
      height: 100%;
    }
    input,
    select,
    button {
    font-family: ${props => props.theme.typography.fontFamily};
    }
    
    .app_content_wrapper{
        width:100%;
    }
    
    a {
        text-decoration: none;
    }
    
    .visually_hidden {
        position: absolute;
        clip: rect(1px 1px 1px 1px);
        padding: 0;
        border: 0;
        height: 1px;
        width: 1px;
        overflow: hidden;
    }

    .content_wrapper_25 {
      padding: 8px 25px;
    }

    .subscription_card{
      padding-left: 24px;
      padding-top: 32px;
      padding-bottom: 37px;
      margin-bottom: 16px;
    }

    .thin_section_divider {
        width: 100%;
        height: 2px;
        background-color: ${props => props.theme.palette.grey.light};
    }

    .red_bullet:before {
        content: '';
        width: 8px;
        height: 8px;
        background-color: ${props => props.theme.palette.error.main};
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
      .green_bullet:before {
        content: '';
        width: 8px;
        height: 8px;
        background-color: ${props => props.theme.palette.success.main};
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
      .grey_bullet:before {
        content: '';
        width: 8px;
        height: 8px;
        background-color: ${props => props.theme.palette.grey.main};
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
      .orange_bullet:before {
        content: '';
        width: 8px;
        height: 8px;
        background-color: ${props => props.theme.palette.warn.main};
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
      [data-title]:hover::before {
        content: attr(data-title);
        position: absolute;
        bottom: 0px;
        display: inline-block;
        padding: 1px 8px;
        border-radius: 2px;
        background:${props => props.theme.palette.grey.brand};
        color: ${props => props.theme.palette.white.main};
        font-size: 12px;
        left: 25px;
        white-space: nowrap;
      }
`
export default GlobalStyles
