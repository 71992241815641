import Axios from 'axios'
import { xApiKey, FEATURE_FLAGS } from 'src/general-config'
const isLocal = process.env.NODE_ENV === 'development'
const isBrowser = typeof window !== 'undefined'
const qs = require('qs')
import { isFeatureActive } from 'src/api/featureFlag'
import { isIdV2Account } from 'src/lib/utils/helper'
export const API_URL = Object.freeze({
  GROUP_TREE: (type) => `group-tree?type=${type}`,
  ITEM_PRODUCT_GROUP: ({ request: { limit, offset, keyword = '' }, type }) =>
    type
      ? `item/product/group?type=${type}&limit=${limit}&offset=${offset}&keyword=${keyword}`
      : `item/product/group?limit=${limit}&offset=${offset}&keyword=${keyword}`,
  GET_ROLE_PERMISSIONS: () => 'ums/v2/users/self',
  GET_PRICE_LIST_CURRENCY: (priceListId) => `price-list/${priceListId}`,
  ITEM_IMPORT_LIST: (limit, offset) =>
    `import-jobs?jobType=PRICE&limit=${limit}&offset=${offset}`,
  GET_AVAILABLE_SHIPPING_TYPES: (isOMSFeatureActiveFlag) => {
    if (isOMSFeatureActiveFlag) {
      return '/api/v2/shipping/query'
    } else {
      return 'shipping/all'
    }
  },
  ITEM_IDS_BY_SKU: () => 'item/itemIds-by-sku',
  //PRODUCT APIs
  PRODUCT_BY_ID: ({ id, priceListId, limit = 10, offset = 0 }) =>
    getProductUrls({ id, priceListId, limit, offset, type: 'product' }),
  ITEMS_SEARCH: () => 'items/search',
  GET_UPLOAD_URL: () => 'uploads/actions/generate-upload-url',
  COUPON_IMPORT_HISTORY: (limit, offset) =>
    `import-jobs?jobType=COUPON-CODE&limit=${limit}&offset=${offset}`,
  COUPON_IMPORT_HISTORY_BY_ID: (jobId) => `import-jobs/${jobId}`,
  ITEM_BY_ID: ({ id, priceListId, limit = 10, offset = 0 }) =>
    getProductUrls({ id, priceListId, limit, offset, type: 'variant' }),
  // PRICE APIs
  GET_PRICE_KIND: () => 'price-kind',
  CREATE_PRICE: () => 'price',

  GET_AVAILABLE_PRICE_LIST: ({ sortBy, sortOrder, offset, notExpired }) => {
    const featurePriceListPagination = isFeatureActive({
      flagName: FEATURE_FLAGS.PRICE_LIST_PAGINATION,
    })
    if (featurePriceListPagination) {
      return `price-list?limit=100&offset=${offset}&sortBy=${sortBy}&sortOrder=${sortOrder}${
        notExpired ? '&filter=not_expired' : ''
      }`
    } else {
      return `price-list?limit=0&sortBy=${sortBy}&sortOrder=${sortOrder}${
        notExpired ? '&filter=not_expired' : ''
      }`
    }
  },
  CREATE_PRICE_LIST: () => 'price-list',
  UPDATE_PRICE_LIST: (id) => `price-list/${id}`,
  //GLOBAL EXCLUSION APIs
  GET_SKU_LIST: (limit, offset) => `sku-list?limit=${limit}&offset=${offset}`,
  CREATE_SKU_LIST: () => 'sku-list',
  UPDATE_SKU_LIST: (id) => `sku-list/${id}`,
  SKU_LIST_GET_BY_ID: (id) => `sku-list/${id}`,
  DELETE_SKU_LIST: (id) => `sku-list/${id}`,
  // PROMO APIs
  PROMO_LIST: () => 'promo/list',
  PROMO_BY_ID: (id) => `promo/${id}`,
  PROMO_CREATE: () => 'promo/create',
  PROMO_UPDATE: (id) => `promo/update/${id}`,
  PROMO_ENABLE_DISABLE: (id) => `promo/${id}/enable-disable`,
  PROMO_DELETE: (id) => `promo/${id}`,
  PROMO_KILL: (id) => `promo/${id}/kill`,

  // COUPON APIs
  COUPON_LIST: () => 'coupon/list',
  COUPON_BY_ID: (id) => `coupon/${id}`,
  COUPON_CREATE: () => 'coupon/create',
  COUPON_UPDATE: (id) => `coupon/update/${id}`,
  COUPON_ENABLE_DISABLE: (id) => `coupon/${id}/enable-disable`,
  COUPON_GENERATE_CODES: () => '/coupon/generate-codes',
  ATTRIBUTE_CONNECTOR: '/pim/group-attributes',
  PRODUCT_SEARCH: '/product/search',
  IMPORT_ERROR_OBJECT: '/download-price-error',
  SEGMENT_POST: '/segment',
  SEGMENT_PUT: ({ segmentId }) => `/segment/${segmentId}`,
  SEGMENTATION_GET: ({ segmentId }) => `/segment/${segmentId}`,
  SEGMENT_DELETE: ({ segmentId }) => `/segment/${segmentId}`,
  SEGMENT_LIST: 'segment/list',
  PIM_CONNECTOR_ACTIVE_ACCOUNT: 'secret-manager/values',
  GET_DEFAULT_CHANNEL: '/v1/merchant-configurations/currencies',
  GET_LOCALES_LIST: 'v1/merchant-configurations/locales',
  GET_COUPON_REDEMPTION_LIST: '/coupon/redemption/list',
  CREATE_EXPORT: '/offers-exports',
  GET_EXPORT_LIST: ({ size, sort, type, offset }) =>
    `/offers-exports?size=${size}&sort=${sort}&type=${type}&offset=${offset}`,
  GENERATE_EXPORT_URL: '/offers-exports/actions/download-export-file',
})

const getProductUrls = ({ id, priceListId, limit, offset, type }) => {
  if (type === 'product') {
    if (
      isFeatureActive({ flagName: FEATURE_FLAGS?.PAGINATION_ON_PRODUCT_PRICE })
    ) {
      return `get-product/${encodeURIComponent(
        id
      )}?priceListId=${priceListId}&limit=${limit}&offset=${offset}`
    } else {
      return `get-product/${encodeURIComponent(id)}?priceListId=${priceListId}`
    }
  } else {
    if (
      isFeatureActive({ flagName: FEATURE_FLAGS?.PAGINATION_ON_PRODUCT_PRICE })
    ) {
      return `item/${id}?priceListId=${priceListId}&limit=${limit}&offset=${offset}`
    } else {
      return `item/${id}?priceListId=${priceListId}`
    }
  }
}

export const xSiteContext = (localEnv) => {
  return {
    'x-site-context': JSON.stringify({
      account: isIdV2Account
        ? sessionStorage.getItem('accountId')
        : sessionStorage.getItem('account'), // account is used in iDv1 and accountID is used in iDv2
      stage: localEnv ? 'dev02' : process.env.SLS_STAGE,
      channel: 12,
      source:
        typeof isFeatureActive === 'function' &&
        isFeatureActive({ flagName: FEATURE_FLAGS.ELIMINATION_OF_EXTRA_FIELD })
          ? 'copilot'
          : '',
    }),
  }
}

const axiosInstance = (_port, domain) => {
  return Axios.create({
    baseURL: isLocal
      ? `https://dev02.copilot.fabric.inc/api-${domain}` /*`http://localhost:${port}/api-${domain}`*/
      : `/api-${domain}`,
    responseType: 'json',
    headers: {
      ...xSiteContext(isLocal),
    },
  })
}

const axiosInstanceDefaultChannel = () => {
  return Axios.create({
    baseURL: `https://${process.env.DEFAULT_LOCALE_ENV}.channel.fabric.zone`,
    responseType: 'json',
    headers: {
      ...xSiteContext(isLocal),
    },
  })
}

const axiosInstanceConnector = () => {
  const accessToken = isBrowser ? sessionStorage.getItem('accessToken') : ''
  const prodEnv = process.env.PIM_CONNECTOR_DOMAIN === 'prod01' ? 'prod' : ''
  return Axios.create({
    baseURL: isLocal
      ? `https://dev02-apigw.pimconnector.fabric.inc/api-pim-external`
      : `https://${process.env.PIM_CONNECTOR_DOMAIN}-apigw.pimconnector${prodEnv}.fabric.inc/api-pim-external`,
    responseType: 'json',
    headers: {
      'x-api-key': xApiKey,
      Authorization: accessToken,
      ...xSiteContext(isLocal),
    },
  })
}

const axiosInstanceOMS = () => {
  const accessToken = isBrowser ? sessionStorage.getItem('accessToken') : ''
  return Axios.create({
    baseURL: isLocal
      ? `https://dev02.oms.fabric.inc`
      : `https://${process.env.OMS_V2_DOMAIN}.oms.fabric.inc`,
    responseType: 'json',
    headers: {
      'x-api-key': xApiKey,
      Authorization: accessToken,
      ...xSiteContext(isLocal),
    },
  })
}

const domainsAxiosInstances = {
  identity: axiosInstance('5001', 'identity'),
  pim: axiosInstance('5002', 'pim'),
  offers: axiosInstance('5003', 'offers'),
  offersConnector: axiosInstanceConnector(),
  oms: axiosInstance('5332', 'oms'),
  omsV2: axiosInstanceOMS(),
  locale: axiosInstanceDefaultChannel(),
}
const { identity, pim, offers, oms, locale } = domainsAxiosInstances
const getLoginUrl = () => {
  return isIdV2Account ? '/auth/login' : '/auth/v2/login'
}
const interceptorsRequest = (domainInstance, _port, domain) => {
  domainInstance.interceptors.request.use(function (config) {
    const accessToken = isBrowser ? sessionStorage.getItem('accessToken') : ''
    const identityV2 = `Bearer ${accessToken}`
    if (accessToken) {
      if (domain) {
        config.baseURL = isLocal
          ? `https://dev02.copilot.fabric.inc/api-${domain}` /*`http://localhost:${port}/api-${domain}`*/
          : `${window.location.origin}/api-${domain}/`
      }
      config.headers.Authorization =
        domain === 'identity' ? identityV2 : accessToken
      config.headers = { ...config.headers, ...xSiteContext(isLocal) }
      return config
    } else {
      const authUrl = isLocal
        ? `http://localhost:9292${getLoginUrl()}`
        : `${window.location.origin}${getLoginUrl()}`
      window.location.href = authUrl
    }
  })
}

const domainResponse = (response) => {
  return response
}

const navigateToLogin = () => {
  const authUrl = isLocal
    ? `http://localhost:9292${getLoginUrl()}`
    : `${window.location.origin}${getLoginUrl()}`
  window.location.href = authUrl
}

const getAccessToken = async () => {
  if (isIdV2Account) {
    let oktaTokenStorage = JSON?.parse(
      localStorage.getItem('okta-token-storage')
    )
    const { idToken, refreshToken } = oktaTokenStorage
    let config = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    let body = qs.stringify({
      grant_type: 'refresh_token',
      refresh_token: refreshToken?.refreshToken,
      client_id: idToken?.clientId,
    })
    const { data } = await Axios.post(refreshToken?.tokenUrl, body, config)
    if (data?.access_token) {
      oktaTokenStorage.accessToken.accessToken = data?.access_token
      oktaTokenStorage.refreshToken.refreshToken = data?.refresh_token
      localStorage.setItem(
        'okta-token-storage',
        JSON.stringify(oktaTokenStorage)
      )
      sessionStorage.setItem('accessToken', data?.access_token)
      return {
        accessToken: data?.access_token,
      }
    }
    return ''
  } else {
    let { data } = await identity.post('/auth/local/refresh', {
      refreshToken: localStorage.getItem('refreshToken'),
    })
    if (data?.accessToken) {
      const { accessToken, refreshToken } = data
      sessionStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
      return data
    }
    return ''
  }
}
let apiRetiralCountFor403 = 0
let MAX_403_RETRIAL_LIMIT = 2
const ErrorResponse = async (error) => {
  if (error?.response?.status === 403) {
    console.error('Received a 403 error => ', JSON.stringify(error?.response))
    if (
      error?.response?.data?.message === 'Forbidden' ||
      apiRetiralCountFor403 >= MAX_403_RETRIAL_LIMIT
    ) {
      apiRetiralCountFor403 = 0
      navigateToLogin()
    }
    apiRetiralCountFor403++
    try {
      const data = await getAccessToken()
      if (data?.accessToken) {
        const { accessToken } = data
        const request = {
          ...error.config,
          headers: {
            ...error.config.headers,
            Authorization: accessToken,
          },
        }
        let response = null
        if (request.baseURL.includes('identity')) {
          console.log('Identity')
          response = await identity.request(request)
        } else if (request.baseURL.includes('pim')) {
          console.log('PIM')
          response = await pim.request(request)
        } else if (request.baseURL.includes('offers')) {
          console.log('Offers')
          response = await offers.request(request)
        } else if (request.baseURL.includes('oms')) {
          console.log('Oms')
          response = await oms.request(request)
        }
        apiRetiralCountFor403 = 0
        return response
      } else {
        return Promise.reject(error)
      }
    } catch (e) {
      navigateToLogin()
    }
  }
  return Promise.reject(error)
}

const interceptorsResponse = (
  domainInstance,
  _domainResponse,
  _ErrorResponse
) => {
  domainInstance.interceptors.response.use(
    (response) => _domainResponse(response),
    (error) => _ErrorResponse(error)
  )
}

interceptorsRequest(pim, '5002', 'pim')
interceptorsRequest(identity, '5001', 'identity')
interceptorsRequest(offers, '5003', 'offers')
interceptorsRequest(oms, '5332', 'oms')
interceptorsRequest(locale)
interceptorsResponse(pim, domainResponse, ErrorResponse)
interceptorsResponse(identity, domainResponse, ErrorResponse)
interceptorsResponse(offers, domainResponse, ErrorResponse)
interceptorsResponse(oms, domainResponse, ErrorResponse)

export default domainsAxiosInstances
