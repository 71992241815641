import React, { useState, useMemo } from 'react'
import { navigate } from '@reach/router'
import { ButtonWithIcon, Modal } from '@teamfabric/copilot-ui'
import UploadFile from './upload-file'
import { StyledImport, StyledOffersFileTransfer } from './styles'
import { hasPermission } from '../../../../api/rolePermissions'
import {
  CREATE_PERMISSIONS,
  READ_PERMISSIONS,
} from '../../../../lib/constants/constants'

const BulkImportPrice = React.memo(() => {
  const { IMPORT_PRICES } = CREATE_PERMISSIONS
  const clickRef = React.useRef()
  const [showBulkImportModal, setShowBulkImportModal] = useState(false),
    showModalState = () => setShowBulkImportModal(true),
    hideModalState = () => setShowBulkImportModal(false),
    UploadFileModal = useMemo(
      () => (
        <Modal
          footerButtons={[]}
          headerButtons={[]}
          onBackdropClick={() => {
            return false
          }}
          onClose={hideModalState}
          padding='32px'
          render={() => <UploadFile onClose={hideModalState} />}
          showCloseButton
          size='small'
          headerText=''
        />
      ),
      []
    )
  const OffersFileTransfer = (
    <StyledOffersFileTransfer className='d-flex justify-start align-center'>
      {hasPermission(IMPORT_PRICES) && (
        <ButtonWithIcon
          emphasis='low'
          icon='Add'
          iconPosition='left'
          isPrimary={false}
          data-testid='add-bulk-button'
          className='button'
          onClick={() => {
            showModalState()
          }}
          text='Add bulk price'
          theme='light'
          disabled={false}
        />
      )}
      {hasPermission(READ_PERMISSIONS.READ_PRICES) && (
        <ButtonWithIcon
          emphasis='low'
          icon=''
          iconPosition='left'
          isPrimary={false}
          data-testid='import-history'
          onClick={() => navigate('/offers/price/bulk')}
          text='Import history'
          theme='light'
          // loading={true}
        />
      )}
    </StyledOffersFileTransfer>
  )
  return (
    <StyledImport ref={clickRef}>
      {OffersFileTransfer}
      {showBulkImportModal && UploadFileModal}
    </StyledImport>
  )
})

export default BulkImportPrice
