import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { navigate } from '@reach/router'
import {
  Button,
  ButtonWithIcon,
  Pagination,
  Dropdown,
  Snackbar,
  Loading,
} from '@teamfabric/copilot-ui'
import cx from 'classnames'
import { itemsSearch, getAvailableEligiblePriceList } from 'src/api/product'
import Search from 'components/search'
import TileView from './tile-view'
import ListView from './list-view'
import BulkImportPrice from './import-file'
import {
  FETCH_RESULTS_FAIL,
  INITIAL_PAGE,
  SEARCH_PAGE_LIMIT,
  INITIAL_CONFIG,
} from './constant'
import {
  StyledViewSwitcher,
  StyledWrapper,
  StyledContent,
  Caption,
  StyledOption,
  StyledOptionButton,
} from './styles'
import SkeletonLoader from 'components/skeleton-loader'
import { isFeatureActive } from 'src/api/featureFlag'
import { FEATURE_FLAGS } from 'src/general-config'
import { hasPermission } from 'src/api/rolePermissions'
import { READ_PERMISSIONS } from 'src/lib/constants/constants'

const CustomOptions = ({
  priceList = [],
  loadMorePriceList,
  showLoader,
  setShowLoader,
  setOptionVisible,
  showLoadMore,
  handlePriceListSelect,
}) => {
  return (
    <>
      {priceList.map((price) => (
        <StyledOption
          onClick={() => {
            handlePriceListSelect(price)
          }}
        >
          <span>{price.name}</span>
          <span className='currency-text'>
            {price.currency}
            {`${price.isDefault ? '(Default)' : ''}`}
          </span>
        </StyledOption>
      ))}
      {showLoader && (
        <StyledOptionButton>
          <Loading
            data-testid='loader'
            className='global'
            strokeColor='#0033B3'
            strokeWidth={5}
          />
        </StyledOptionButton>
      )}
      {showLoadMore && (
        <StyledOption>
          <StyledOptionButton>
            <Button
              onClick={() => {
                setOptionVisible(true)
                setShowLoader(true)
                loadMorePriceList()
              }}
              text='Load More'
              isPrimary={false}
              size='small'
            />
          </StyledOptionButton>
        </StyledOption>
      )}
    </>
  )
}

const ProductList = () => {
  const [hasSearched, setHasSearched] = useState(false)
  const [isTileView, setIsTileView] = useState(() => {
    const urlParams = new URL(window.location.href).searchParams
    const view = urlParams.get('view')
    if (view === null) return true
    return view === 'tile'
  })
  const [selectedPriceListId, setSelectedPriceListId] = useState(() => {
    const urlParams = new URL(window.location.href).searchParams
    return urlParams.get('priceListId') || ''
  })
  const [selectedPriceList, setSelectedPriceList] = useState({})
  const [priceList, setPriceList] = useState([])
  const [showLoadMore, setShowLoadMore] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [showErrorToaster, setErrorToaster] = useState(false)
  const [searchErrorMsg, setSearchErrorMsg] = useState('')
  const [priceListLoader, setPriceListLoader] = useState(false)
  const [itemList, setItemList] = useState([])
  const [queryConfig, setQueryConfig] = useState(INITIAL_CONFIG)
  const [caption, setCaption] = useState('')
  const [query, setQuery] = useState(() => {
    const urlParams = new URL(window.location.href).searchParams
    const page = urlParams.get('page')
    const search = urlParams.get('search')

    return { page: page || INITIAL_PAGE, search: search || '' }
  })

  const searchTerm = query.search
  const currentPage = query.page

  const fetchSearchResults = async (page = currentPage) => {
    setIsLoading(true)
    try {
      const offset = parseInt(page - 1) * parseInt(SEARCH_PAGE_LIMIT)
      const reqData = {
        limit: SEARCH_PAGE_LIMIT,
        offset: offset,
        keyword: searchTerm,
        sku: true,
        isListView: !isTileView,
        priceListId: selectedPriceListId === '' ? 0 : selectedPriceListId,
      }
      const { data } = await itemsSearch(reqData)
      const results = data.results.map((i) => {
        return { ...i, children: i.items }
      })

      setQuery((prev) => ({ page, search: prev.search }))
      setItemList(results)
      setQueryConfig({ ...data.query, offset })
      setIsLoading(false)
    } catch (error) {
      if (error?.response?.data) {
        const { message = '' } = error?.response?.data || {}
        setSearchErrorMsg(message)
      }
      setErrorToaster(true)
      setIsLoading(false)
    }
  }

  const switchView = (showOrHideTileView) => {
    if (isTileView === showOrHideTileView) return
    setIsTileView(showOrHideTileView)
  }

  const handleSearch = (search) => {
    if (search || !itemList.length) {
      setHasSearched(true)
      setQuery({ page: INITIAL_PAGE, search: search })
    }

    if (!search) {
      setQuery({ page: INITIAL_PAGE, search: '' })
    }
  }

  useEffect(() => {
    async function fetchPriceList() {
      try {
        const urlParams = new URL(window.location.href).searchParams
        const { data = {} } = await getAvailableEligiblePriceList({
          offset: 0,
          notExpired: true,
        })
        if (Boolean(data?.data) === false || data?.data?.length === 0) {
          setPriceList([])
          setSelectedPriceList({})
          setSearchErrorMsg(
            'You have no active price list. Please create one from the Settings.'
          )
          setErrorToaster(true)
          setIsLoading(false)
          return
        }
        if (data?.query?.count) {
          setShowLoadMore(data?.query?.count > data.data.length)
        }
        setPriceList(data.data)

        let selected = data.data.find((i) => i.isDefault)
        if (urlParams.get('priceListId')) {
          selected =
            data.data.find(
              (i) => i.priceListId.toString() === urlParams.get('priceListId')
            ) || selected
        }

        if (!urlParams.get('priceListId')) {
          setSelectedPriceListId(selected?.priceListId || '')
        }
        setSelectedPriceList(selected)
      } catch (e) {
        setErrorToaster(true)
        setIsLoading(false)
      }
    }
    fetchPriceList()
  }, [])

  const loadMorePriceList = async () => {
    const offset = priceList.length
    const { data = {}, query: results = {} } =
      await getAvailableEligiblePriceList({ offset, notExpired: true })
    const list = [...priceList, ...data.data]
    if (list.length >= results?.count) {
      setShowLoadMore(false)
    }
    setPriceList(list)
    setPriceListLoader(false)
  }

  useEffect(() => {
    const url = new URL(window.location.href)
    const urlParams = url.searchParams
    urlParams.set('view', isTileView ? 'tile' : 'list')
    urlParams.set('page', parseInt(query.page))
    urlParams.set('search', query.search)
    urlParams.set('priceListId', selectedPriceListId)
    navigate(url.toString())
  }, [query, isTileView, selectedPriceListId])
  useEffect(() => {
    const urlParams = new URL(window.location.href).searchParams
    if (
      window.localStorage.getItem('environment') === 'testing' ||
      urlParams.get('priceListId')
    ) {
      fetchSearchResults()
    }
  }, [query.search, isTileView, selectedPriceListId])

  const onCloseToaster = useCallback(() => setErrorToaster(false), [])

  const ErrorToaster = useMemo(
    () => (
      <div className='snack-bar' data-testid='snack-bar'>
        <Snackbar
          onDismiss={onCloseToaster}
          label={searchErrorMsg || FETCH_RESULTS_FAIL}
          dismissable
          kind='alert'
          show
          textColor='#FFFFFF'
          width='600px'
        />
      </div>
    ),
    [onCloseToaster, searchErrorMsg]
  )

  const showPagination = !isLoading && itemList && itemList.length > 0
  let customCaption =
    queryConfig.count > 10000
      ? caption.replace('of 10000', `of ${queryConfig.count}`)
      : caption
  return (
    <>
      {!isLoading ? (
        <StyledWrapper>
          {showErrorToaster && ErrorToaster}
          <StyledContent>
            {hasPermission(READ_PERMISSIONS.READ_PRICES) && (
              <div className='content_padding'>
                <div>
                  <BulkImportPrice />
                  <SearchProduct
                    onSearch={handleSearch}
                    switchView={switchView}
                    initialSearchTerm={searchTerm}
                    isTileView={isTileView}
                    hasSearched={hasSearched}
                    priceList={priceList}
                    selectedPriceList={selectedPriceList}
                    setSelectedPriceList={setSelectedPriceList}
                    setSelectedPriceListId={setSelectedPriceListId}
                    priceListLoader={priceListLoader}
                    loadMorePriceList={loadMorePriceList}
                    setPriceListLoader={setPriceListLoader}
                    showLoadMore={showLoadMore}
                  />
                </div>
                {<Caption>{`${customCaption} SKUs`}</Caption>}
                {isTileView ? (
                  <TileView
                    data={itemList}
                    hasSearched={hasSearched}
                    currency={selectedPriceList?.currency}
                  />
                ) : (
                  <ListView
                    data={itemList}
                    hasSearched={hasSearched}
                    currency={selectedPriceList?.currency}
                  />
                )}
                {
                  <div
                    className={`loadmore_container ${
                      !showPagination && 'show_pagination'
                    }`}
                  >
                    <Pagination
                      totalRecords={
                        queryConfig.count > 10000 ? 10000 : queryConfig.count
                      }
                      perPage={queryConfig.limit}
                      handlePagination={fetchSearchResults}
                      activePageNumber={parseInt(currentPage)}
                      setCaption={setCaption}
                    />
                  </div>
                }
              </div>
            )}
          </StyledContent>
        </StyledWrapper>
      ) : (
        <SkeletonLoader type='price' />
      )}
    </>
  )
}

const SearchProduct = ({
  initialSearchTerm,
  isTileView,
  switchView,
  onSearch,
  priceList,
  selectedPriceList,
  setSelectedPriceList,
  setSelectedPriceListId,
  loadMorePriceList,
  priceListLoader,
  setPriceListLoader,
  showLoadMore,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [optionVisible, setOptionVisible] = useState(false)
  useEffect(() => {
    setSearchTerm(initialSearchTerm)
  }, [initialSearchTerm])

  const handleSearch = () => {
    searchTerm && onSearch(searchTerm)
  }

  const handleReset = () => {
    setSearchTerm('')
    onSearch('')
  }

  const handlePriceListSelect = (priceListArr) => {
    setSelectedPriceList(priceListArr)
    setSelectedPriceListId(priceListArr.priceListId)
    setOptionVisible(false)
  }

  const featurePriceListPagination = isFeatureActive({
    flagName: FEATURE_FLAGS.PRICE_LIST_PAGINATION,
  })
  const customOptions = featurePriceListPagination
    ? {
        isOptionsVisible: optionVisible,
        customOptionsView: (
          <CustomOptions
            handlePriceListSelect={handlePriceListSelect}
            showLoadMore={showLoadMore}
            optionVisible={optionVisible}
            setOptionVisible={setOptionVisible}
            priceList={priceList}
            showLoader={priceListLoader}
            setShowLoader={setPriceListLoader}
            loadMorePriceList={loadMorePriceList}
          />
        ),
      }
    : {}

  return (
    <>
      <div className='search_count'>
        <Search
          onChange={(value) => setSearchTerm(value)}
          keyword={searchTerm}
          placeholder='Search SKUs by ID/Title'
          type='text'
          onSubmit={handleSearch}
          onReset={handleReset}
        />
        <Button onClick={handleSearch} text='Search' isPrimary isTertiary />
        <div className='actions'>
          <StyledViewSwitcher>
            <ButtonWithIcon
              icon='GridView'
              emphasis='low'
              size='large'
              isPrimary={false}
              data-testid='tile-view'
              isRoundIcon
              onClick={() => switchView(true)}
              className={cx('switch', { active: isTileView })}
            />
            <ButtonWithIcon
              icon='ListView'
              emphasis='low'
              size='large'
              isPrimary={false}
              data-testid='list-view'
              isRoundIcon
              onClick={() => switchView(false)}
              className={cx('switch', { active: !isTileView })}
            />
          </StyledViewSwitcher>
        </div>
      </div>

      <div className='price-list-wrapper'>
        <Dropdown
          title='price-list-selector'
          className='price-list'
          type='small'
          disable={false}
          value={selectedPriceList}
          options={priceList}
          id='selectedPriceListDropdown'
          name='selectedPriceListDropdown'
          optionsPlacement='right'
          data-testid='product-price-list'
          onSelect={(priceListData) => {
            setSelectedPriceList(priceListData)
            setSelectedPriceListId(priceListData.priceListId)
          }}
          {...customOptions}
        />
      </div>
    </>
  )
}

export default ProductList
