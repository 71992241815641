import React, { useState, useEffect } from 'react'
import { navigate, useLocation } from '@reach/router'
import { Navigation } from '@teamfabric/copilot-ui'
import { StyledNavigation } from './styles'

import { PAGELINKS } from 'lib/data/constants'
import { hasPermission } from 'src/api/rolePermissions'
import { READ_PERMISSIONS } from 'src/lib/constants/constants'
import { getNavigationLinks } from 'src/lib/utils/helper'
import { isFeatureActive } from 'src/api/featureFlag'
import { FEATURE_FLAGS } from 'src/general-config'
export const SideNav = ({ links, clickHandler }) => {
  const getPermitLinks = (routes) => {
    return routes.filter((link) => {
      if (link.label === 'Settings') {
        return hasPermission(READ_PERMISSIONS.READ_SETTINGS)
      } else if (link.label === 'Promotions') {
        return hasPermission(READ_PERMISSIONS.READ_PROMOTIONS)
      } else if (link.label === 'Coupons') {
        return hasPermission(READ_PERMISSIONS.READ_COUPONS)
      } else if (link.label === 'Pricing') {
        return hasPermission(READ_PERMISSIONS.READ_PRICES)
      } else {
        return link
      }
    })
  }
  return (
    <div className='side-navigation'>
      <Navigation
        className='secondary'
        links={getPermitLinks(links)}
        onClick={(e) => clickHandler(e)}
        orientation='vertical'
      />
    </div>
  )
}

const SideNavigation = () => {
  const { pathname } = useLocation()
  const [links, setLinks] = useState(getNavigationLinks(PAGELINKS))
  useEffect(() => {
    let active
    if (pathname.includes('price')) {
      active = 1
    }
    if (pathname.includes('promo')) {
      active = 2
    }
    if (pathname.includes('coupon')) {
      //With the redemption export feature the coupon will have child routes
      //setting active = 3 will show all the child routes as active
      //so if coupon has child routes we do not want to set active=3
      if (
        isFeatureActive({ flagName: FEATURE_FLAGS.REDEMPTION_EXPORT }) === false
      ) {
        active = 3
      } else {
        if (pathname.includes('coupon-list')) {
          active = 5
        } else if (pathname.includes('coupon/redemption')) {
          active = 6
        }
      }
    }
    if (pathname.includes('settings')) {
      active = 4
    }
    let updatedLinks = links.map((link) => {
      if (link.id === active) {
        return {
          ...link,
          active: true,
        }
      }
      if (link.children) {
        link.children = link.children.map((childLink) => {
          if (childLink.id === active) {
            return {
              ...childLink,
              active: true,
            }
          }

          return {
            ...childLink,
            active: false,
          }
        })
      }
      return {
        ...link,
        active: false,
      }
    })
    setLinks(updatedLinks)
  }, [pathname])

  const clickHandler = (e) => {
    e.preventDefault()
    if (e.target.pathname) {
      navigate(e.target.pathname)
      setActiveTab(e.target.pathname)
    }
  }

  const setActiveTab = (url) => {
    let updatedLinks = [...links]

    for (let index = 0; index < updatedLinks.length; index++) {
      if (updatedLinks[index].children) {
        for (let idx = 0; idx < updatedLinks[index].children.length; idx++) {
          if (updatedLinks[index].children[idx].url === url) {
            updatedLinks[index].children[idx].active = true
          } else {
            updatedLinks[index].children[idx].active = false
          }
        }
      }
      if (updatedLinks[index].url && updatedLinks[index].url === url) {
        updatedLinks[index].active = true
      } else {
        updatedLinks[index].active = false
      }
    }
    setLinks(updatedLinks)
  }

  return (
    <StyledNavigation>
      <SideNav clickHandler={clickHandler} links={links} />
    </StyledNavigation>
  )
}

export default SideNavigation
